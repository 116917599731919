import { PartialMaintenanceTask } from 'routes/MaintenanceTask/MaintenanceTask';
import {
  MaintenanceRow,
  MaintenanceTask,
  MaintenanceTaskStatus,
  MaintenanceRowComplementReason
} from 'types';
import { productResolvers } from 'utils';
import {
  isOperationMaintenanceRow,
  isArticleMaintenanceRow,
  isExternalMaintenanceRow,
  isMileageAllowanceMaintenanceRow,
  isShippingMaintenanceRow
} from 'utils/typeGuards';

const getPrice = (maintenanceRow: MaintenanceRow) => {
  if (isArticleMaintenanceRow(maintenanceRow)) {
    if (!maintenanceRow.orderItem) return '';
    return productResolvers.getArticle(maintenanceRow.orderItem)?.price;
  }
  else {
    return maintenanceRow.price;
  }
};

const getCode = (maintenanceRow: MaintenanceRow) => {
  if (isMileageAllowanceMaintenanceRow(maintenanceRow)) {
    return 'Reseersättning';
  }

  if (isShippingMaintenanceRow(maintenanceRow)) {
    return 'Frakt';
  }

  if (isOperationMaintenanceRow(maintenanceRow)) {
    return maintenanceRow.operationCode?.code;
  }

  if (isArticleMaintenanceRow(maintenanceRow)) {
    if (!maintenanceRow.orderItem) return '';
    return productResolvers.getArticle(maintenanceRow.orderItem)?.articleNumber;
  }

  if (isOperationMaintenanceRow(maintenanceRow)) {
    return maintenanceRow.operationCode?.code;
  }
};

const getDescription = (maintenanceRow: MaintenanceRow) => {
  const { getArticle, getName } = productResolvers;

  if (isMileageAllowanceMaintenanceRow(maintenanceRow)) {
    return '';
  }

  if (isExternalMaintenanceRow(maintenanceRow)) {
    return maintenanceRow.externalCostDescription;
  }

  if (isArticleMaintenanceRow(maintenanceRow)) {
    /*
    An ArticleMaintenanceRow either has an order item or one of sparePart/billOfMaterial.
    The article can therefore be found in the nested order item or in one of sparePart/billOfMaterial
    */
    const article = maintenanceRow.orderItem
      ? getArticle(maintenanceRow.orderItem)
      : getArticle(maintenanceRow);

    return getName(article);
  }

  if (isOperationMaintenanceRow(maintenanceRow)) {
    return (
      maintenanceRow.customOperationDescription ??
      maintenanceRow.operationCode?.description
    );
  }
};

const requiresImageCompletion = ({ complementReason }: MaintenanceRow) => {
  return complementReason === MaintenanceRowComplementReason.NoDamageImage;
};

const isClosed = (
  maintenanceTask: Partial<MaintenanceTask> | PartialMaintenanceTask
) => {
  if (!maintenanceTask?.status) {
    return false;
  }

  return [
    MaintenanceTaskStatus.Approved,
    MaintenanceTaskStatus.Credited,
    MaintenanceTaskStatus.Declined
  ].includes(maintenanceTask.status);
};

export const maintenanceTaskResolvers = {
  ...{
    getPrice,
    getCode,
    getDescription,
    requiresImageCompletion,
    isClosed
  }
};
