import React from 'react';

import { Link } from 'components/tools';
import { Article } from 'types';
import { productResolvers } from 'utils';
import {
  isStockVehicle,
  isEquipment,
  isBillOfMaterial
} from 'utils/typeGuards';

import styles from './WebshopProductInfo.module.scss';

interface WebshopProductInfoProps {
  article: Article;
}

const WebshopProductInfo = ({ article }: WebshopProductInfoProps) => {
  if (isEquipment(article) && article.remark)
    return (
      <div>
        <h3 className={styles.informationHeading}>Beskrivning</h3>
        <p>{article.remark}</p>
      </div>
    );

  if (isStockVehicle(article) && article.factoryOptions.length > 0)
    return (
      <div>
        <h3 className={styles.informationHeading}>Fabrikstillval</h3>
        <div className={`grid ${styles.optionItems}`}>
          {article.factoryOptions.map((option) => (
            <div key={option.id} className="grid__item--width-6/12">
              <p className={styles.optionItemLabel}>
                {option.factoryOptionType?.description}
              </p>
              <p>{option.description}</p>
            </div>
          ))}
        </div>
      </div>
    );

  if (isBillOfMaterial(article) && article.bomRows.length > 0) {
    return (
      <div>
        {article.remark ? (<div>
          <h3 className={styles.informationHeading}>Beskrivning</h3>
          <p>{article.remark}</p></div>) : ''}
        <h3 className={styles.informationHeading}>Innehåller</h3>
        <div className={styles.optionItems}>
          {article.bomRows.map((row) => {
            const constituentArticle = productResolvers.getArticle(row);
            if (!constituentArticle) return null;

            return (
              <div key={constituentArticle.id}>
                <div className={`grid grid--between ${styles.optionItemLabel}`}>
                  <Link
                    to={productResolvers.getUrl(constituentArticle)}
                    className={styles.link}
                  >
                    <p>{productResolvers.getName(constituentArticle)}</p>
                  </Link>
                  <p>{row.linkQuantity}st</p>
                </div>
                <p>{productResolvers.getSequence(constituentArticle)}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

export default WebshopProductInfo;
